import React from "react";
import { Breadcrumb, CardComponent } from "../../components";
import {travelHistories} from "../../services/apiService";

const data = await travelHistories();

const TravelHistory = () => {

  return (
    <div>

      <Breadcrumb headerName="Histórico de viajes" backNav={"/taxi/home"} />
      <div className="d-flex flex-column justify-content-between align-content-center container mt-8 view-cursor">
        <div className="vh-100">
        {data.map(function(item, index) {
          return (
          <CardComponent
                start={item.start}
                end={item.end}
                date={item.date}
                status={item.status}
                cardNo={item.id}
                key={item.id}
              />)

        })}

            
        </div>
      </div>
    </div>
  );
};

export default TravelHistory;
