import axios from 'axios';

// Configura axios para enviar cookies con las solicitudes
axios.defaults.withCredentials = true;

// Solicitud inicial para obtener el token CSRF
export const getCsrfToken = async () => {
    try {
        let token = await axios.get('http://localhost/sanctum/csrf-cookie');
        if (token) {
            axios.interceptors.request.use(config => {
                    config.headers['X-CSRF-TOKEN'] = token.data.csrf_token;
                
                return config;
            }, error => {
                return Promise.reject(error);
            });
        }

    } catch (error) {
        console.error('Error fetching CSRF cookie:', error);
    }
};

// Configura axios para enviar automáticamente el token CSRF en los encabezados de las solicitudes
