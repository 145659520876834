import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  theme: localStorage.getItem("theme") === "true" || false,
};

const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme(state, action) {
      localStorage.setItem("theme", action.payload);
      state.theme = action.payload;
    },
  },
});

export const { toggleTheme } = ThemeSlice.actions;
export default ThemeSlice.reducer;
