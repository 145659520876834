export const LandingLoader = () => <div id="preloader"></div>;

export const TaxiLoader = () => (
  <div className="loader-mask">
    <div className="pre-loader">
      <div></div>
      <div></div>
    </div>
  </div>
);
