import Modal from "react-bootstrap/Modal";
import "./modal.css";

const ModalComponent = (props) => {
  const {
    children,
    footerComponent,
    header,
    footerFlag,
    headerFlag,
    closeShowModal,
    ...rest
  } = props;
  return (
    <Modal onHide={closeShowModal} {...rest}>
      {headerFlag && (
        <Modal.Header closeButton>{header ? header : null}</Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>{footerFlag ? footerComponent : null}</Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
