import { BrowserRouter } from "react-router-dom";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Aos from "aos";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import TaxiModeLayout from "./Layout/TaxiModeLayout";

import Home from "./pages/Home/Home"

import CancelRequest from "./pages/Reservation/CancelRequest";
import TravelHistory from "./pages/Reservation/TravelHistory";
import LoginComponent from "./pages/Login/LoginComponent";
import AcceptedTrip from "./pages/Trip/AcceptedTrip";


const App = () => {
  const themeGetter = useSelector((state) => state.theme.theme);

  useEffect(() => {
    if (themeGetter) document.body.classList.add("dark-mode-active");
    else document.body.classList.remove("dark-mode-active");
  }, [themeGetter]);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);
  const isAuthenticated = localStorage.getItem('userToken') ? true : false;
  
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/login" element={isAuthenticated ? <Navigate to="/taxi/home" /> : <LoginComponent /> }/>
      <Route path="/" element={<Navigate to="/taxi/home" />} />
      <Route path="/taxi" element={<TaxiModeLayout />}>
        <Route path="home" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
        <Route path="travel-history" element={isAuthenticated ? <TravelHistory /> : <Navigate to="/login" />} />
        <Route path="cancel-request" element={isAuthenticated ? <CancelRequest /> : <Navigate to="/login" />} />
        <Route path="accepted-request" element={isAuthenticated ? <AcceptedTrip /> : <Navigate to="/login" />} />
      </Route>
    </Routes>
    </BrowserRouter>
  );
};

export default App;
