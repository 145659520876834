import { useNavigate } from "react-router-dom";
import { TrackTripsIcon } from "../../assets/TaxiModeImage";
import "./card.css";

const CardComponent = (props) => {
  const { start, end, path, status, date, cardNo, themeStatus } = props;
  const navigate = useNavigate();
  return (
    <div className={`container view-cursor  ${cardNo !== 0 ? "mt-2" : ""}`}>
      <div
        className={`row p-4 align-items-center zero-border rounded-4 border shadow-lg ${
          themeStatus ? "card-dark-bg" : ""
        }`}
        onClick={() => navigate(path)}
      >
        <div className="d-grid padding-0">
          <div className="d-flex">
            <TrackTripsIcon style={{ height: "69.63px" }} />
            <div id="tracking" className="padding-left-20">
              <div className="tracking-list">
                <div className="tracking-item min-vh-7">
                  <div className="tracking-content">
                    <div className="col-md">
                      <div className="floating-label">
                        <label className="fs-3 fw-semibold">{start}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tracking-item" style={{ minHeight: "inherit" }}>
                  <div className="tracking-content">
                    <div className="col-md">
                      <div className="floating-label">
                        <label className="fs-3 fw-semibold">{end}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-3" />
        <div className="d-flex justify-content-between align-baseline padding-0">
          <h2 className="roboto-text font-size-12 fw-bolder margintop-5">
            {date}
          </h2>
          <h4
            className={`roboto-text txt-${status} mt-2 text-uppercase font-size-15 fw-bolder`}
          >
            {status}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
