import {
  DarkHomeInviteFriendsIcon,
  DarkHomeMenuIcon,
  DarkHomeNotificationsIcon,
  DarkHomeSettingsIcon,
  DarkHomeTripIcon,
  DarkHomeWalletIcon,
  HomeInviteFriendsIcon,
  HomeMenuIcon,
  HomeNotificationsIcon,
  HomeSettingsIcon,
  HomeTripIcon,
  HomeWalletIcon,
} from "../../assets/TaxiModeImage";

const data = [
  {
    name: "Home",
    Image: HomeMenuIcon,
    DarkImage: DarkHomeMenuIcon,
    path: "/taxi/home",
  },
  {
    name: "Viajes",
    Image: HomeTripIcon,
    DarkImage: DarkHomeTripIcon,
    path: "/taxi/travel-history",
  },



];

export default data;
