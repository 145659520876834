import React, { Fragment, useState, useEffect } from "react";
import { FaTaxi } from 'react-icons/fa';
import axios from 'axios';

import NewTripFooter from '../../components/NewTrip/NewTripFooter';
import {
  SwapImgIcon,
  TrackPointIcon,
} from '../../assets/TaxiModeImage';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SetReservationStatus from "../../services/SetReservationStatus";
import {checkAsignedReservation} from "../../services/apiService";

const ShowReservation = ({ name, showTitle }) => {
  


  const token = localStorage.getItem('userToken');

  const themeGetter = useSelector(({ theme }) => theme.theme);
  const navigate = useNavigate();


  // comprobar si el usuario tiene aun alguna reserva pendiente de finalizar
  const fetchReservations = async () => {
    try {
        const reservation = await checkAsignedReservation();
        console.log(reservation);
        if (reservation && Object.keys(reservation).length > 0) {
          navigate('/taxi/accepted-request', { state: { reservation } });
        }
      
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };
  // end 


  
  const handleHeight = useMediaQuery({
    maxHeight: "660px",
  });

  const [isCanceled, setIsCanceled] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);





  
  const isIphone5 = useMediaQuery({
    maxHeight: "615px",
  });

  const isIphone320w = useMediaQuery({
    maxWidth: "320px",
  });

  const isH690 = useMediaQuery({
    minHeight: "690px",
  });





  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [reservation, setReservation] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);


  const handleClickAccept = () => {
    setIsAccepted(true);
    navigate('/taxi/accepted-request', { state: { reservation } });

  };



  useEffect(() => {
      // Función para obtener la geolocalización del usuario
      const getLocation = () => {
          if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {
                  setLatitude(position.coords.latitude);
                  setLongitude(position.coords.longitude);
              }, (error) => {
                  console.error("Error obteniendo la ubicación: ", error);
              });
          } else {
              console.error("Geolocalización no soportada por el navegador.");
          }
      };

      // Función para consultar la API
      const checkReservations = () => {
          if (latitude && longitude) {
              axios.post(process.env.REACT_APP_API_URL + '/v1/check-reservations', {
                  latitude,
                  longitude
              })
              .then(response => {
                  const foundReservations = response.data ?? false;
                  setReservation(foundReservations);
                  if (foundReservations) {
                      // Si se encuentra alguna reserva, esperar 20 segundos
                      setIsWaiting(true);
                    
                      setTimeout(() => {
                          setIsWaiting(false);
                      }, 20000);
                  }

              })
              .catch(error => {
                  console.error("Error consultando las reservas: ", error);
              });
          }
      };

      // Obtener la ubicación inicialmente
      getLocation();

      // Intervalo para consultar la API cada 5 segundos
      const interval = setInterval(() => {
        if (!isWaiting) {
            getLocation();
            checkReservations();
        }
    }, 5000);

      // Limpiar el intervalo al desmontar el componente
      return () => clearInterval(interval);
  }, [latitude, longitude]);

  const handleClickCancel = () => {
    setIsCanceled(true);
    SetReservationStatus(reservation, 0);
  };

  useEffect(() => {
    fetchReservations();
  }, []);


 
  return (
    <>
    <NewTripFooter
      show={(reservation.id > 0 && isAccepted == false && isCanceled == false ? true  : false)}
      placement={"bottom"}
      backdrop={false}
      className="rounded-top-new mob_view"
      tabIndex="999"
      style={{
        height: isIphone5
          ? "400px"
          : handleHeight
          ? "450px"
          : name === "arrival"
          ? "575px"
          : name === "step4" && isH690
          ? "521px"
          : "500px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          minHeight:
            isIphone320w && name === "arrival"
              ? "140%"
              : isIphone5
              ? "122%"
              : handleHeight
              ? name === "arrival"
                ? "90%"
                : "108%"
              : name === "step4"
              ? "108%"
              : "98%",
        }}
      >
        {showTitle && (
          <Fragment>
            <div className="mt-2 text-center">
              <h3 className="roboto-text tm-primary-txt-color fw-bold">
                Nuevo Viaje
              </h3>
            </div>
            <hr />
          </Fragment>
        )}
        <div className="d-flex flex-column flex-md-row gap-2 py-md-5 mt-3 ">
          <div className="position-relative">
            <div className="d-flex align-items-left gap-3 justify-content-left">
            <div style={{ overflow: 'none' }}>
              <FaTaxi size={50} style={{ color: 'black' }} />
            </div>
              <div className="me-6">
              <span >Nombre del cliente: </span>
                <span
                  className={`fw-semibold font-size-14`}
                >
                {reservation.clientName}
                  </span>
              </div>
              <div className="d-flex">
             
               
              
              </div>
            </div>
          </div>

          <hr />

        

          <div className="d-grid gap-4 d-flex justify-content-evenly">
          <div className="w-100 d-flex justify-content-between">
            <TrackPointIcon style={{ height: "179px", margin: "-50px 0" }} />
            <div id="tracking" style={{ margin: "0 auto" }}>
              <div className="tracking-list">
                <div className="tracking-item">
                  <div className="tracking-content">
                    <div className="col-md">
                      <div className="floating-label">
                        <input
                          type="text"
                          id="pickup"
                          className="form-control remove-borderB my-form-control input-label-lg form-control-lg fw-bolder font-size-18 border-none padding045012"
                          name="pickup"
                          value={reservation.pickUpLocation}
                        />

                        <label
                          htmlFor="pickup"
                          style={{ fontSize: "12px" }}
                          id="newTripLabel"
                        >
                          Recogida
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tracking-item-section padding202010">
                  <hr style={{ margin: "0.5rem 0" }} />
                </div>
                <div className="tracking-item">
                  <div className="tracking-content">
                    <div className="col-md">
                      <div className="floating-label">
                        <input
                          type="text"
                          id="dropoff"
                          className="form-control remove-borderB my-form-control input-label-lg form-control-lg fw-bolder font-size-18 border-none padding045012"
                          name="dropOff"
                          value={reservation.dropOffLocation}
                        />
                        <label
                          htmlFor="dropoff"
                          style={{ fontSize: "12px" }}
                          id="newTripLabel"
                        >
                          Destino
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SwapImgIcon
              style={{
                height: "144px",
                margin: "-15px 0",
                zIndex: "1",
              }}
            />
          </div>
        </div>


          <div className="row">
            <div
              className={`d-flex gap-3 justify-content-center fixed-bottom py-3 bg-white ${
                themeGetter ? "tm-secondary-bg-color" : "bg-white"
              }`}
            >
              <button
                className=" btn btn-outline-secondary btn-lg col-6 d-flex align-items-center justify-content-center tm-btn-outline-secondary font-size-18"
                type="button"
                onClick={() => handleClickAccept()}
                >
                Aceptar
              </button>
              <button
                className="btn btn-primary btn-lg col-6 d-flex align-items-center justify-content-center tm-secondary-button font-size-18"
                type="button"
                onClick={() => handleClickCancel()}
              >
                Rechazar
              </button>
            </div>
          </div>
        </div>
      </div>


    </NewTripFooter>
    
  </>
  );
};




export default ShowReservation;
