import React from "react";
import { Offcanvas } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const NewTripFooter = (props) => {
  const { children, headerFlag, header, show, ...rest } = props;
  // const isMobile = useMediaQuery({
  //   maxWidth: "360px",
  // });

  const isHeight = useMediaQuery({
    maxHeight: "675px",
  });

  return (
    <div>
      <Offcanvas show={show} {...rest}>
        {headerFlag && (
          <Offcanvas.Header className="mt-3">{header}</Offcanvas.Header>
        )}
        <Offcanvas.Body
          className={`mob_scroll_view_step4 ${isHeight ? "" : "noScroll"}`}
        >
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default NewTripFooter;
