import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import OpenRouteService from 'openrouteservice-js';

// Soluciona el problema de los íconos predeterminados de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const MapComponent = () => {
  const [position, setPosition] = useState(null);
  const [route, setRoute] = useState(null);
  const destination = [42.0502, 2.2631]; // Coordenadas de Torelló, Barcelona

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPosition([latitude, longitude]);
      },
      (error) => {
        console.error('Error obtaining location', error);
      },
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0,
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  useEffect(() => {
    if (position) {
      const Directions = new OpenRouteService.Directions({
        api_key: '5b3ce3597851110001cf6248ad779f2b692c40b0bf4c9052c81f7c8c' // Reemplaza con tu API key
      });

      Directions.calculate({
        coordinates: [position, destination],
        profile: 'driving-car', // Cambia el perfil a 'driving-car' para rutas en coche
        format: 'geojson',
        radiuses: [5000, 5000] // Aumenta el radio de búsqueda
      })
      .then(response => {
        if (response && response.features && response.features.length > 0) {
          const coordinates = response.features[0].geometry.coordinates.map(coord => [coord[1], coord[0]]);
          setRoute(coordinates);
        } else {
          console.error('No route found in the response');
        }
      })
      .catch(err => {
        console.error('Error obtaining route:', err.message);
      });
    }
  }, [position]);

  const MapUpdate = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      if (center) {
        map.setView(center, 17);
      }
    }, [center, map]);
    return null;
  };

  if (!position) {
    return <div>Loading...</div>;
  }

  return (
    <MapContainer center={position} zoom={17} style={{ height: '100vh', width: '100%' }}>
      <MapUpdate center={position} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>Your current location</Popup>
      </Marker>
      <Marker position={destination}>
        <Popup>Destination: Torelló, Barcelona</Popup>
      </Marker>
      {route && route.length > 0 && (
        <Polyline
          positions={route}
          color="blue"
        />
      )}
    </MapContainer>
  );
};

export default MapComponent;
