import React from "react";
import { useSelector } from "react-redux";
import {
  DarkHomeLogoutIcon,
  DarkImgProfileImage,
  HomeLogoutIcon,
  ImgProfileImage,
} from "../../assets/TaxiModeImage";
import { Link } from "react-router-dom";
import "./navigation.css";
import navigationData from "./navigationData";

const Navigation = ({ setMenuStatus, setLogoutModal }) => {
  const themeGetter = useSelector((state) => state.theme.theme);
  return (
    <nav id="side-bar">
      <div className="nav-wrapper">
        <div className="content-nav-1">
          <div className="sidebar-edit-profile">
            <Link
              to="/taxi-mode/edit-account"
              className=" d-flex align-items-center p-3 border-0 mb-0 rounded-4 nav-item"
            >
              <img
                className="rounded-pill me-3 ch-50"
                src={themeGetter ? DarkImgProfileImage : ImgProfileImage}
                alt=""
              />
              <div>
                <h4 className="mb-0 tm-secondary-txt-color sidebar-icon-name roboto-text">
                  AreaTransfers
                </h4>

              </div>
            </Link>
          </div>
          <div className="sidebar-options mt-4">
            {navigationData.map(({ name, path, Image, DarkImage }) => {
              return (
                <Link
                  className="nav-item"
                  to={path}
                  key={name}
                  onClick={() => setMenuStatus(false)}
                >
                  <span className="p-relative me-3">
                    {themeGetter ? <DarkImage /> : <Image />}
                    {name === "Notifications" ? (
                      <span className="badges"></span>
                    ) : null}
                  </span>
                  &nbsp;
                  <span className="sidebar-icon-name roboto-text">{name}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div
          className={`txt-logout my-bg-color bottom-nav ${
            !themeGetter && "bg-white"
          }`}
          onClick={() => {
            setLogoutModal(true);
            setMenuStatus(false);
          }}
        >
          <span className="me-3 ">
            {themeGetter ? <DarkHomeLogoutIcon /> : <HomeLogoutIcon />}
          </span>
          &nbsp;
          <Link className="grey-text sidebar-icon-name">Logout</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
