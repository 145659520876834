import axios from 'axios';



export const checkAsignedReservation = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/check-asigned-reservation');
      return response.data;
    } catch (error) {
      console.error("Error consultando las reservas: ", error);
      throw error;
    }
};

export const travelHistories = async () => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/travel-histories');
    return response.data;
  } catch (error) {
    console.error("Error consultando historial trayectos: ", error);
    throw error;
  }
};