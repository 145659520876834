import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import authService from '../../services/authService'

const LogoutFooter = ({ setLogoutModal }) => {
  const handleLogout = async () => {
    await authService.logout();
    window.location.reload(); // Recargar la página o redirigir a una página pública
};

  const navigate = useNavigate();
  return (
    <div>
      <div className="d-flex gap-3 justify-content-center mb-3 px-4 logoutFooter ">
        <Button
          className="tm-secondary-button"
          variant="dark"
          size="lg"
          onClick={() => {
            handleLogout();
            setLogoutModal(false);;
          }}
        >
          Yes
        </Button>
      </div>
      <div className="d-flex gap-3 justify-content-center mb-3 px-4">
        <Button
          className="text-dark fw-bold body.dark-mode-active tm-btn-outline-secondary"
          variant="outline-secondary"
          size="lg"
          onClick={() => setLogoutModal(false)}
        >
          No
        </Button>
      </div>
    </div>
  );
};

export default LogoutFooter;
