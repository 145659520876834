import axios from 'axios';

const login = async (email, password) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
    if (response.data.token) {
        localStorage.setItem('userToken', response.data.token);
    }
    return response.data;
};

const logout = async () => {
    const token = localStorage.getItem('userToken');
    console.log(token);
    if (token) {
        console.log(token);
        await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        localStorage.removeItem('userToken');
    }
};

export default {
    login,
    logout
};
