import { useSelector } from "react-redux";

const TaxiModeImage = () => {
  return (
    <div className="images mb-6">
     <b><h1 style={{color:"white", fontSize:"3rem"}}>AreaTaxi</h1></b>
     <b><h1 style={{color:"white", fontSize:"1rem"}}>Drivers</h1></b>
    </div>
  );
};

export default TaxiModeImage;
