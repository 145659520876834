import React, { useEffect, useState } from "react";
import authService from '../../services/authService';

import "./auth.css";
import TaxiModeImage from "../../components/TaxiMode/TaxiModeImage"
import axios from "axios";

import { useSelector } from "react-redux";
import { countries, getCountryCode } from "countries-list";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useMediaQuery } from "react-responsive";

const LoginComponent = () => {


  const ThemeGetter = useSelector((state) => state.theme.theme);
  const isIphone5 = useMediaQuery({ maxWidth: "320px" });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
      e.preventDefault();
      try {
          await authService.login(email, password);
          window.location.href = '../'; // Recargar la página o redirigir a una página protegida
      } catch (err) {
          setError('Credenciales incorrectas');
      }
  };


  return (
    <div className="container justify-content-center px-0">
      <div className="vh-100">
        <div className="top-heading-section text-center">
          <div className="head-text mt-5">
            <TaxiModeImage />
            <div className="head-title mb-text-content"></div>
            <div className="head-sub-title padding-lr"></div>
          </div>
        </div>
        <div className="tm-container padding-lr">
          <div className={"d-flex gap-3 d-flex justify-content-center"}>
            <form onSubmit={handleLogin}>
              <div className="floating-label mt-5">
                <div>
                  <input
                    name="username"
                    type="text"
                    id="username"
                    className="ps-3 form-control phoneInput input-label-lg form-control-lg col-7 padding8x16"
                    placeholder="Email"
                    style={{ width: "230px" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mt-2">
                  <input
                    name="password"
                    type="password"
                    id="password"
                    className="ps-3 form-control phoneInput input-label-lg form-control-lg col-7 padding8x16"
                    placeholder="Contraseña"
                    style={{ width: "230px" }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-3"
                style={{ width: "230px" }}
              >
                Iniciar sesión
              </button>
              {error && <p>{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
