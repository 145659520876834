import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowIcon, DarkArrowIcon } from "../../assets/TaxiModeImage";

const Breadcrumb = ({ headerName, imgFlag, Img, backNav }) => {
  const themeGetter = useSelector((state) => state.theme.theme);
  const navigateBack = useNavigate();
  return (
    <div className="bg-white shadow-lg top-header-radius fixed-top breadcrumb-padding">
      <div className="d-flex align-items-center">
        <div className="gap-4 d-flex align-items-center">
          <div onClick={() => navigateBack(backNav)}>
            {themeGetter ? <DarkArrowIcon /> : <ArrowIcon />}
          </div>
          <h3 className="fw-bold m-0 font-size-22 roboto-text">{headerName}</h3>
        </div>
        <div className="ms-auto">
          {imgFlag && <Img className="shadow-lg rounded-4" />}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
