import React, { useState } from "react";
import { Breadcrumb, Modal } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SetReservationStatus from "../../services/SetReservationStatus";

const CancelRequest = () => {
  const themeGetter = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery({ maxWidth: "355px" });
  const isBigMobile = useMediaQuery({ minHeight: "738px" });
  const isIphone5 = useMediaQuery({ maxWidth: "320px" });

  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState({
    checked1: false,
    checked2: false,
    checked7: false,
  });

  const reservation = location.reservation || {};


  const handleClickSendAndCancel = () => {
    navigate('/taxi/home')

     SetReservationStatus(reservation, 0, isChecked);
  };

  const footerComp = (
    <div className="d-flex gap-3 justify-content-center mb-3 px-4">
      <button
        className="btn btn-primary d-flex align-items-center justify-content-center tm-secondary-button font-size-18 w-100"
        type="button"
        onClick={() => {
          navigate("/taxi/home");
          setShowModal(false);
        }}
      >
        Back To Home
      </button>
    </div>
  );

  const handleCheckboxChange = (checkRequest) => {
    setIsChecked({ ...isChecked, [checkRequest]: !isChecked[checkRequest] });
  };

  return (
    <div className="px-0 overflow-x-hidden overflow-y-scroll">
      <div
        style={{
          height: isIphone5
            ? "125vh"
            : isMobile
            ? "105vh"
            : isBigMobile
            ? "100%"
            : "100vh",
        }}
      >

        <Breadcrumb
          headerName="Solicitud de Cancelación"
          backNav={location.state || "/taxi/home"}
        />
        <div className="row mt-126">
          <div className="px-5">
            <p className="font-size-16 fw-500">
              Selecciona la razón de la cancelación
            </p>
          </div>

          <div className="px-5 py-2 mb-2">
            <label className="taxi_container">
              <input
                checked={isChecked.checked1}
                type="checkbox"
                id="chk1"
                onChange={() => handleCheckboxChange("checked1")}
              />
              <div className="checkmark"></div>
              <label id="chk1" className="me-4">
                El cliente no está en la ubicación
              </label>
            </label>
          </div>

          <div className="px-5 py-2 mb-2">
            <label className="taxi_container">
              <input
                checked={isChecked.checked2}
                type="checkbox"
                id="chk2"
                onChange={() => handleCheckboxChange("checked2")}
              />
              <div className="checkmark"></div>
              <label id="chk2" className="me-4">
                El cliente denegó el viaje
              </label>
            </label>
          </div>



          <div className="px-5 py-2 mb-2">
            <div className="col-12 mb-4">
              <label
                htmlFor="email"
                className="form-labe fw-semibold mb-2 font-size-16"
              >
                Otra razón
              </label>
              <textarea
                type="text"
                className="form-control line-height input-border-style input-border-radius padding-left-20"
                id="email"
                placeholder="escibe la razón..."
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className={`d-flex gap-3 justify-content-center fixed-bottom py-3 ${
              themeGetter ? "tm-secondary-bg-color" : "bg-white"
            }`}
          >
            <button
              className="btn btn-primary btn-lg col d-flex align-items-center justify-content-center tm-secondary-button"
              type="button"
              onClick={() => handleClickSendAndCancel()}
            >
              Enviar y Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelRequest;
