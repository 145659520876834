import React, { Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";
import "../../custom.css";
import { TaxiLoader } from "../../components";
import { Helmet } from "react-helmet";

function TaxiModeLayout() {
  return (
    <Suspense fallback={<TaxiLoader />}>
      <Fragment>
        <Helmet>
          <title>AreaTransfers | Home</title>
        </Helmet>
        <Outlet />
      </Fragment>
    </Suspense>
  );
}

export default TaxiModeLayout;
