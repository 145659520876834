import { configureStore } from "@reduxjs/toolkit";
import ThemeSlice from "./action/ThemeRedux";

const Store = configureStore({
  reducer: {
    theme: ThemeSlice,
  },
});

export default Store;
