import React, { Fragment, useEffect, useState } from "react";
import {
  DarkGpsLocationIcon,
  DarkSideMenuIcon,
  EnableLocationIcon,
  GpsLocationIcon,
  ImgMapIcon,
  LogoutIcon,
  SideMenuIcon,
} from "../../assets/TaxiModeImage";
import { useSelector } from "react-redux";
import LogoutModalFooter from "../../components/LogoutModalFooter/LogoutFooter"
import "./home.css";
import { Modal, TaxiLoader, MapComponent } from "../../components";
import { Button } from "react-bootstrap";
import Navigation from "../../components/Navigation";
import { Helmet } from "react-helmet";
import ShowReservation from "../../components/ShowReservation/ShowReservation";

const Home = () => {
  const themeGetter = useSelector((state) => state.theme.theme);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showGpsModal, setShowGpsModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const GpsFooter = () => {
    return (
      <div className="w-100 h-100">
        {loading && <TaxiLoader />}
        <div className="d-flex gap-3 justify-content-center mb-3 px-4">
          <Button
            className="tm-secondary-button"
            variant="dark"
            size="lg"
            onClick={() => setShowGpsModal(false)}
          >
            Habilitar Ahora
          </Button>
        </div>
        <div className="d-flex gap-3 justify-content-center mb-3 px-4">
          <Button
            className="text-dark fw-bold tm-btn-outline-secondary"
            variant="outline-secondary"
            size="lg"
            onClick={() => setShowGpsModal(false)}
          >
            ¡Ahora no!
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const time = setTimeout(function () {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(time);
    };
  }, []);

  
  return (
    <div className={`${isMenuOpen ? "position-fixed" : ""}`}>
      {loading && <TaxiLoader />}
      <Helmet>
        <title>Home</title>
      </Helmet>
      {logoutModal && (
        <Modal
          closeShowModal={() => setLogoutModal(false)}
          show={logoutModal}
          centered={true}
          footerComponent={
            <LogoutModalFooter setLogoutModal={setLogoutModal} />
          }
          footerFlag={true}
          headerFlag={true}
          backdrop="static"
          keyboard={false}
        >
          <div className=" w-100 d-flexmodal ">
            <LogoutIcon className="modalimg mb-3" />
            <h2
              className="roboto-text fw-bolder px-4"
              style={{ fontSize: "calc(1.325rem + .9vw)" }}
            >
              ¿Confirmas cerrar sesión?
            </h2>
            <p className="h3modal-text txt-grey roboto-text font-size-15 px-4 mb-3">
              ¿Estás seguro de salir?
            </p>
          </div>
        </Modal>
      )}
      {showGpsModal && (
        <Modal
          headerFlag={true}
          closeShowModal={setShowGpsModal}
          show={showGpsModal}
          centered={true}
          footerComponent={<GpsFooter />}
          footerFlag={true}
          backdrop="static"
        >
          <div className=" w-100 d-flexmodal ">
            <EnableLocationIcon className="modalimg mb-3" />
            <h2
              className="roboto-text fw-bolder px-4"
              style={{ fontSize: "calc(1.325rem + .9vw)" }}
            >
              Habilitar geolocalización
            </h2>
            <p className="h3modal-text txt-grey roboto-text font-size-15 px-4 mb-3">
              Necesitamos acceder a su geolocalización para poder usar nuestros servicios
            </p>
          </div>
        </Modal>
      )}
      <div
        className={`container px-0 ${isMenuOpen ? "side-bar-open" : ""}`}
        style={{ margin: "0" }}
      >
        <div
          className="fixed-top p-4"
          style={{ backgroundColor: "rgba(0,0,0,0)" }}
        >
          <div
            className="d-flex align-items-center"
            style={{ backgroundColor: "rgba(0,0,0,0)" }}
          >
            <div className="gap-3 d-flex align-items-center">
              <div
                className="toggle osahan-toggle text-dark m-0"
                onClick={() => setIsMenuOpen(true)}
              >
                {themeGetter ? (
                  <DarkSideMenuIcon className="shadow-lg rounded-4" />
                ) : (
                  <SideMenuIcon className="shadow-lg rounded-4" />
                )}
              </div>
            </div>
            <div
              className="ms-auto gap-3 gps-icon d-flex align-items-center view-cursor"
              onClick={() => setShowGpsModal(true)}
            >
              {themeGetter ? (
                <DarkGpsLocationIcon className="shadow-lg rounded-4 view-cursor" />
              ) : (
                <GpsLocationIcon className="shadow-lg rounded-4 view-cursor" />
              )}
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <Navigation
            style={{ display: "none" }}
            setMenuStatus={setIsMenuOpen}
            setLogoutModal={setLogoutModal}
          />
        )}
        {isMenuOpen && (
          <div
            className={`overlay ${themeGetter ? "extra-dark" : ""}`}
            onClick={() => setIsMenuOpen(false)}
          ></div>
        )}
        <div>
        <MapComponent />

        </div>
        <Fragment>
      <Helmet>
        <title>AreaTransfers - Drivers</title>
      </Helmet>
      <ShowReservation showTitle={true} name="arrival" />;
    </Fragment>
      </div>
    </div>
  );
};

export default Home;
