import React, { useState, Fragment } from "react";
import { FaTaxi, FaMap } from "react-icons/fa";
import {
  SwapImgIcon,
  MapViewIcon,
  DarkMapViewIcon,
  TrackPointIcon,
} from "../../assets/TaxiModeImage";
import { Breadcrumb, Modal } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SetReservationStatus from "../../services/SetReservationStatus";
import MapComponentGuide from "../../components/MapComponentGuide/MapComponentGuide";
import NewTripFooter from "../../components/NewTrip/NewTripFooter";

const AcceptedRequest = () => {
  const themeGetter = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery({ maxWidth: "355px" });
  const isBigMobile = useMediaQuery({ minHeight: "738px" });
  const isIphone5 = useMediaQuery({ maxWidth: "320px" });

  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState({
    checked1: false,
    checked2: false,
    checked7: false,
  });

  const reservation = location.state?.reservation || {}; // Accede a los datos de 'state'



  const footerComp = (
    <div className="d-flex gap-3 justify-content-center mb-3 px-4">
      <button
        className="btn btn-primary d-flex align-items-center justify-content-center tm-secondary-button font-size-18 w-100"
        type="button"
        onClick={() => {
          navigate("/taxi/home");
          setShowModal(false);
        }}
      >
        Back To Home
      </button>
    </div>
  );



  const handleClickEndTrip = () => {
    SetReservationStatus(reservation, 1);
    navigate('/taxi/home');
  };



  return (
    <>
      <NewTripFooter
        show="true"
        placement={"bottom"}
        backdrop={false}
        className="rounded-top-new mob_view"
        tabIndex="999"
        style={{
          maxHeight: "660px",
          height: "65VH",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            minHeight: "140%",
          }}
        >
          <Fragment>
            <div className="mt-2 text-center">
              <h3 className="roboto-text tm-primary-txt-color fw-bold">
                Datos del Viaje
              </h3>
            </div>
            <hr />
          </Fragment>

          <div className="d-flex flex-column flex-md-row gap-2 py-md-5 mt-3 ">
            <div className="position-relative">
              <div className="d-flex align-items-left gap-3 justify-content-left">
                <div style={{ overflow: "none" }}>
                  <FaTaxi size={50} style={{ color: "black" }} />
                </div>
                <div className="me-6">
                  <span>Nombre del cliente: </span>
                  <span className={`fw-semibold font-size-14`}>
                    {reservation.clientName}
                  </span>
                </div>
                <div className="d-flex"></div>
              </div>
            </div>

            <hr />

            <div className="d-grid gap-4 d-flex justify-content-evenly">
              <div className="w-100 d-flex justify-content-between">
                <TrackPointIcon
                  style={{ height: "179px", margin: "-50px 0" }}
                />
                <div id="tracking" style={{ margin: "0 auto" }}>
                  <div className="tracking-list">
                    <div className="tracking-item">
                      <div className="tracking-content">
                        <div className="col-md">
                          <div className="floating-label">
                            <input
                              type="text"
                              id="pickup"
                              className="form-control remove-borderB my-form-control input-label-lg form-control-lg fw-bolder font-size-18 border-none padding045012"
                              name="pickup"
                              value={reservation.pickUpLocation}
                            />

                            <label
                              htmlFor="pickup"
                              style={{ fontSize: "12px" }}
                              id="newTripLabel"
                            >
                              Recogida
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tracking-item-section padding202010">
                      <hr style={{ margin: "0.5rem 0" }} />
                    </div>
                    <div className="tracking-item">
                      <div className="tracking-content">
                        <div className="col-md">
                          <div className="floating-label">
                            <input
                              type="text"
                              id="dropoff"
                              className="form-control remove-borderB my-form-control input-label-lg form-control-lg fw-bolder font-size-18 border-none padding045012"
                              name="dropOff"
                              value={reservation.dropOffLocation}
                            />
                            <label
                              htmlFor="dropoff"
                              style={{ fontSize: "12px" }}
                              id="newTripLabel"
                            >
                              Destino
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SwapImgIcon
                  style={{
                    height: "144px",
                    margin: "-15px 0",
                    zIndex: "1",
                  }}
                />
              </div>
            </div>

            <div className="mb-2">
            <button
                  className="btn btn-primary btn-lg col-12 d-flex align-items-center justify-content-center font-size-18"
                  style={{backgroundColor: "var(--bs-red)"}}
                  type="button"
                >
                 <FaMap size={30} style={{ color: "white" }} /> &nbsp; Ir a la ubicación
                </button>
  
            </div>

            <div className="row">
              <div
                className={`d-flex gap-3 justify-content-center fixed-bottom py-3 bg-white ${
                  themeGetter ? "tm-secondary-bg-color" : "bg-white"
                }`}
              >
                <button
                  className="btn btn-primary btn-lg col-12 d-flex align-items-center justify-content-center tm-secondary-button font-size-18"
                  type="button"
                  onClick={handleClickEndTrip}
                >
                  Finalizar Trayecto
                </button>
              </div>
            </div>
          </div>
        </div>
      </NewTripFooter>
    </>
  );
};

export default AcceptedRequest;
